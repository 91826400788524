<template>
  <ion-content>
    <div v-show="showSkeleton" class="mx-3 my-3">
      <ion-skeleton-text class="mx-1 my-2" animated style="width: 40%; height: 18px"></ion-skeleton-text>
      <ion-row class="mt-2">
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 22vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 28vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 34vw; height: 44px"></ion-skeleton-text>

        <ion-skeleton-text class="mx-1 my-2" animated style="width: 22vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 40vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 22vw; height: 44px"></ion-skeleton-text>

        <ion-skeleton-text class="mx-1 my-2" animated style="width: 36vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 38vw; height: 44px"></ion-skeleton-text>

        <ion-skeleton-text class="mx-1 my-2" animated style="width: 30vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 28vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 26vw; height: 44px"></ion-skeleton-text>

        <ion-skeleton-text class="mx-1 my-2" animated style="width: 26vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 20vw; height: 44px"></ion-skeleton-text>
        <ion-skeleton-text class="mx-1 my-2" animated style="width: 38vw; height: 44px"></ion-skeleton-text>
      </ion-row>
    </div>
    <div v-show="!showSkeleton" class="mx-3 my-3">
      <ion-label class="tb">
        {{ $t('product_category') }}
      </ion-label>
      <div class="mt-2">
        <ion-grid>
          <ion-row>
            <div
              v-for="cate in listData"
              :key="cate.id"
              @click="selectCategory(cate)"
              class="product-cate px-2 my-1"
            >
              <ion-img
                v-if="cate.image !== null || cate.image !== ''"
                class="cate-img"
                :src="categoryPhoto[cate.name]"
              ></ion-img>
              <ion-text class="ml-2">{{ cate.name }}</ion-text>
            </div>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </ion-content>
</template>
<script>
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { ACTIONS } from '@/modules/b2b/store/category/actions';
import { getImage } from '@/utils/';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/category');

export default defineComponent({
  emits: ['chooseCategory', 'setListProductcategory'],
  computed: {
    ...mapGetters(['list'])
  },
  setup() {
    const showSkeleton = ref(true);
    return {
      showSkeleton
    };
  },
  data() {
    return {
      listData: [],
      categoryPhoto: {}
    };
  },
  async mounted() {
    await this[ACTIONS.GET_CATEGORIES]();
    for (const cat of this.list) {
      this.categoryPhoto[cat.name] = await getImage(cat.name, IMAGE_PATH.CATEGORY_ICON);
    }
    this.listData = [...this.list];
    if (this.listData?.length > 0) {
      this.$emit('setListProductcategory', this.listData);
    }
    this.showSkeleton = false;
  },

  methods: {
    ...mapActions([ACTIONS.GET_CATEGORIES]),
    selectCategory(category) {
      this.$emit('chooseCategory', category);
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
